.footer {
    position: relative;
    overflow: hidden;
    padding-top: 110px;

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 110px;
        height: 275px;
        width: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        background: $color-gray;
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__titles {
        color: $color-white;
        font-size: 1.375rem;
        font-weight: $font-weight-bold;
        margin: 0 0 20px;
    }

    &__infos {
        position: relative;
        z-index: 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        height: 275px;
        padding: 40px 0;
        color: $color-white;
        line-height: 1.25rem;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: -80px;
            left: -120px;
            width: 940px;
            height: 700px;
            background-color: $color-main;
            border-radius: 30px;
            transform: rotate(-3.62deg);
        }

        &__title {
            position: relative;
            font-size: 1.375rem;
            line-height: 1.5rem;
            padding-bottom: 25px;
            font-weight: $font-weight-bold;
            font-family: $font-family--heading;

            &:before {
                content: "";
                position: absolute;
                bottom: 15px;
                left: 0;
                height: 2px;
                width: 40px;
                border-radius: 5px;
                background-color: $color-third;
            }
        }
        
        &__coordonnees {
            width: 255px;
        }
    
        &__horaires {
            width: 415px;
            margin-left: 120px;
        }

        .button {
            margin: 30px 0 0 0;

            svg {
                fill: $color-white;
                margin-right: 8px;
                transition: fill $duration ease-in-out;
            }

            &:hover, &:focus {
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }
        }
    }

    &__buttons {
        width: 270px;

        &__link {
            display: inline-block;
            width: 100%;

            &:first-child {
                margin-bottom: 20px;
            }
        }

        .button {
            color: $color-white;
            width: 100%;
            margin: 0 0 20px 0;

            svg {
                fill: $color-white;
                margin-right: 8px;
            }

            &:last-child {
                margin: 0;
            }

            &:hover, &:focus {
                color: $color-main;

                svg {
                    fill: $color-main;
                }
            }
        }
    }

    &__menu {
        padding: 30px 0;
        background: $color-white;

        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__label {
            height: 50px;
            margin-right: 140px;

            img {
                transition: filter $duration ease-in-out;
            }

            &:hover {

                img {
                    filter: grayscale(.9);
                }
            }
        }

        ul {
            position: relative;
            z-index: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: 625px;
        }

        li {
            position: relative;
            padding: 5px 18px;

            &:first-child {
                padding-left: 0;
            }

            &:nth-child(4) {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            a {
                color: $color-white;

                &:hover {
                        color: $color-third;
                }
            } 

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 12px;
                background: $color-white;
                transform: translateY(-50%);
            }

            &:last-child::before {
                background :transparent;
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {

        &__infos {

            &:before {
                width: 745px;
                height: 550px;
                left: -60px;
            }

            &__horaires {
                width: 300px;
                margin-left: 70px;
            }
        }

        &__buttons {
            width: 220px;

            .button {
                padding: 15px 10px;
            }
        }

        &__menu {

            ul {
                width: 590px;

                li {
                    padding: 5px 12px;
                }
            }

            &__label {
                margin-right: 90px;
            }
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .footer {
        padding: 0;

        &:before {
            top: 0;
        }
        
        &__container {
            flex-direction: column;
        }

        &__infos {
            order: 1;
            margin-top: 125px;
            width: 100%;

            &:before {
                width: 1200px;
                height: 700px;
                left: -50%;
            }
        }

        &__buttons {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 50px;
            margin-bottom: 15px;

            .button {
                width: 300px;
                margin: 0;
            }
        }

        &__menu {
            padding: 0 0 30px 0;

            &__container {
                flex-direction: column;
            }

            ul {
                width: 525px;
                justify-content: center;
                padding-bottom: 35px;

                li {
                    padding: 5px 10px;

                    &:nth-child(3) {
                        &:before {
                            content: unset;
                        }
                    }
                }
            }

            &__label {
                z-index: 1;
                margin: 0;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {

    .footer {

        &__buttons {
            display: block;
        }

        &:before {
            height: 400px;
        }

        &__infos {
            display: block;
            height: 495px;
            padding-bottom: 55px;

            &:before {
                width: 960px;
                height: 1000px;
                left: -100%;
            }

            &__coordonnees {
                margin-bottom: 50px;
                width: 100%;
            }

            &__horaires {
                width: 100%;
                margin: 0;
            }
        }

        &__menu {
            padding-bottom: 40px;

            ul {
                width: 100%;
                justify-content: flex-start;
                padding-bottom: 35px;

                li {
                    padding: 5px 10px;

                    &:nth-child(2), &:nth-child(3), &:nth-child(5) {
                        padding-left: 0;
                    }
                }
            }
        }
    }

}