.front-page {
    position: relative;
    z-index: 2;

    .home {

        &__section {
            position: relative;
            margin-top: -170px;
            height: 670px;

            &__container {
                display: flex;
                justify-content: space-between;
            }

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                top: 170px;
                left: 0;
                right: 0;
                margin: 0 auto;
                height: 500px;
                width: 100%;
                background-color: $color-main;
            }
        }

        &__title {
            font-size: 2.5rem;
            color: $color-main;
        }
    }
}


//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    .swiper-container {
        height: 670px;
        width: 100%;

        .swiper-slide {
            position: relative;

            &:before {
                content: "";
                z-index: 2;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 100%;
                height: 100%;
                pointer-events: none;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
            }
            
            img {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                object-position: top;
            }
        }

        .container--pagination {
            position: absolute;
            z-index: 5;
            right: 0;
            left: 0;
            bottom: 140px;
            height: 40px;
            width: 105px;
            padding: 0;
            display: flex;
            transform: translateX(530px);
            justify-content: space-between;
            pointer-events: none;

            div {
                position: initial;
                margin-top: 0;
                pointer-events: auto;
                width: 40px;
                height: 40px;
                background: $color-second;
                border-radius: $border-radius;
                transition: all $duration ease-in-out;

                svg {
                    width: 40px;
                    height: 40px;
                    fill: $color-white;
                }

                &::after {
                    content: none!important;
                }

                &:hover {
                    background-color: $color-main;
                }
            }
        }
    }

    .slideshow {
        display: inherit;
        position: relative;
        width: 100%;
        height: 100%;

        .container--slideshow {
            position: relative;
            height: 100%;
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            right: 0;
            bottom: 50px;
            width: 500px;
            height: auto;
            padding: 20px;
            background: $color-second--rgba;
            color: $color-white;
        }

        &__title {
            margin-bottom: 15px;
            font-size: 1.375rem;
            font-weight: $font-weight-bold;
        }
    }

    .video-container {
        height: 670px;
        width: 100%;

        .slideshow {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            video,
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }

            img {
                display: none;
            }
        }
    }
}


//======================================================================================================
// Quick Access
//======================================================================================================
.access {
    background-color: $color-light;
    height: 315px;

    &__list {
        display: flex;
        justify-content: space-between;
    }

    &__item {
        width: 170px;
        height: 170px;
        margin-top: -100px;

        &__link {
            display: flex;
            height: 170px;
            padding: 10px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: $border-radius;
            background-color: $color-white;
            box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);

            &:hover {
                box-shadow: unset;

                svg {
                    transform: translateY(-5px);

                    path[fill="#E2001A"] {
                        
                        fill: $color-third;
                    }
                }
            }
        }

        &__label {
            display: flex;
            align-items: center;
            height: 50px;
            margin-top: 10px;
            color: $color-text;
            font-size: .875rem;
            line-height: .938rem;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            text-align: center;
        }

        svg {
            transition: all $duration ease-in-out;
            transform: translateY(0px);

            path[fill="#E2001A"] {
                transition: all $duration ease-in-out;
            }
        }
    }
}


//======================================================================================================
// Posts
//======================================================================================================
.post {
    width: 670px;

    .swiper-container {
        height: 430px;
        margin-bottom: 30px;
        border-radius: 10px;
        box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);
    }

    &__heading {
        display: flex;
        position: relative;
        margin-bottom: 30px;
    }

    &__title {
        padding: 0;
        margin: 0;
    }

    &__link {
        position: relative;
        display: inline-block;
        width: 670px;
        height: 430px;
    }

    &__content {
        height: 430px;

        img {
            border-radius: $border-radius;
        }

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-bg--image;
            background-position: center;
            background-size: 25%;
        }

    }

    &__infos {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding: 30px;
        border-radius: $border-radius;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 22.09%, rgba(255, 255, 255, 0.93) 91.91%);
        transition: all $duration ease-in-out;

        &__title {
            font-size: 1.75rem;
            line-height: 1.875rem;
            margin: 0;
            padding-bottom: 10px;
        }

        &__introduction {
            font-size: 0.875rem;
            line-height: 1.125rem;
            padding-bottom: 15px;
        }

        &__button {
            padding: 5px 15px;
            margin: 0;
            border-radius: 5px;
            font-size: 0.75rem;
            line-height: 0.813rem;
            width: fit-content;
            color: $color-white;
            background-color: $color-main;
            border-color: $color-main;
            transition: all $duration ease-in-out;

            &:hover, &:focus {
                color: $color-main;
                background-color: $color-white;
                border-color: $color-main;
            }
        }

        &:hover {
            padding-bottom: 40px;
            background-color: $color-third--rgba;
        }
    }

    &__button {
        margin: 0;

        &:hover, &:focus {
            color: $color-white;
            border-color: $color-second;
        }
    }

    &__navigation {
        position: absolute;
        z-index: 5;
        left: 230px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 40px;
        width: 105px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        pointer-events: none;

        div {
            position: initial;
            margin-top: 0;
            pointer-events: auto;
            width: 40px;
            height: 40px;
            background: $color-second;
            border-radius: $border-radius;
            transition: all $duration ease-in-out;

            svg {
                width: 40px;
                height: 40px;
                fill: $color-white;
            }

            &::after {
                content: none!important;
            }

            &:hover {
                background-color: $color-main;
            }
        }
    }
}

//======================================================================================================
// Events
//======================================================================================================
.events {

    width: 470px;

    &__heading {
        margin-bottom: 30px;
    }

    &__title {
        padding: 0;
        margin: 0;
    }

    &__link {
        position: relative;
        z-index: 0;
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 200px;
        padding: 20px;
        margin-bottom: 30px;
        border-radius: 10px;
        background-color: $color-white;
        box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);
        transition: all .15s ease-in-out;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            box-shadow: unset;

            .events__date {
                background-color: $color-main;
                color: $color-white;

                &:before {
                    background-color: $color-third;
                }

                svg {
                    fill: $color-white;
                }
            }
        }
    }

    &__date {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 75px;
        height: 155px;
        margin: 10px 25px 0 5px;
        background-color: $color-third;
        border-radius: 5px;
        transition: all .15s ease-in-out;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            left: -9px;
            top: -8px;
            width: 75px;
            height: 155px;
            border-radius: 5px;
            background-color: $color-main;
            transform: rotate(-2.7deg);
            transition: all .15s ease-in-out;
        }

        p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;

            span {
                font-family: $font-family--heading;
            }
        }

        &__day {
            font-size: 1.875rem;
            line-height: 1.875rem;
            font-weight: $font-weight-bold;
        }
        
        &__month {
            font-size: 1rem;
            line-height: 1.25rem;
            font-weight: $font-weight-medium;
        }

        svg {
            width: 12px;
            height: 7px;
            margin: 5px 0;
            transition: all .15s ease-in-out;
        }
    }

    &__infos {
        width: 290px;
        height: fit-content;

        &__title {
            margin: 0 0 20px 0;
        }

        &__button {
            margin: 0;
            padding: 5px 15px;
            border-radius: 5px;
            font-size: 0.75rem;
            line-height: 0.813rem;
            color: $color-white;
            background-color: $color-main;
            border-color: $color-main;
            transition: all $duration ease-in-out;

            &:hover, &:focus {
                color: $color-main;
                background-color: $color-white;
                border-color: $color-main;
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
    }

    &__button {
        color: $color-white;
        margin: 0;

        &:last-child {
            color: $color-main;
            background-color: $color-third;

            &:hover, &:focus {
                color: $color-white;
                background-color: $color-main;
            }
        }
    }
}


//======================================================================================================
// Zoom
//======================================================================================================
.zoom {
    padding-top: 110px;
    margin-bottom: 70px;

    &__heading {
        position: relative;
        margin-bottom: 30px;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: -50px;
            left: -50px;
            width: 260px;
            height: 260px;
            border-radius: $border-radius;
            background-color: $color-second;
            transform: rotate(-3.62deg);
        }
    }

    &__title {
        color: $color-white!important;
        padding: 0;
        margin: 0;

        span {
            font-weight: $font-weight-light;
        }
    }

    &__image {
        position: relative;

        img {
            border-radius: $border-radius;
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 130px;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: $border-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        transition: all $duration ease-in-out;

        &__title {
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: $font-weight-bold;
            color: white;
            text-transform: uppercase;
            text-align: center;
            text-shadow: 0px 0px 10px #000000, 0px 0px 10px #000000;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.7);
        }
    }

    &__navigation {
        position: absolute;
        z-index: 5;
        left: 250px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 40px;
        width: 105px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        pointer-events: none;

        div {
            position: initial;
            margin-top: 0;
            pointer-events: auto;
            width: 40px;
            height: 40px;
            background: $color-second;
            border-radius: $border-radius;
            transition: all $duration ease-in-out;

            svg {
                width: 40px;
                height: 40px;
                fill: $color-white;
            }

            &::after {
                content: none!important;
            }

            &:hover {
                background-color: $color-main;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .front-page {
        .home {

            &__section {
                height: 600px;

                &:before {
                    height: 435px;
                }
            }
        }
    }

    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .home-slideshow {

        .swiper-container {
            height: 600px;

            .container {

                &--pagination {
                    width: 100px;
                    bottom: 120px;
                    transform: translateX(420px);
                }
            }
        }
    }


    //======================================================================================================
    // Quick Access
    //======================================================================================================
    .access {

        &__item {
            width: 140px;
            height: 140px;
            margin-top: -80px;
        
            &__link {
                height: 140px;
                padding: 10px;

                svg {
                    height: 65px;
                    width: 65px;
                }
            }

            &__label {
                padding: 0;
            }
        }
    }


    //======================================================================================================
    // Posts
    //======================================================================================================
    .post {
        width: 540px;

        .swiper-container {
            height: 380px;
            margin-bottom: 20px;
        }

        &__content, &__link {
            height: 380px;
            width: 540px;
        }

        &__content {
            img {
                width: 540px;
                height: 380px;
            }
        }
    }


    //======================================================================================================
    // Events
    //======================================================================================================
    .events {
        width: 380px;

        &__link {
            height: 180px;
            margin-bottom: 20px;
            padding: 0;
        }

        &__date {
            margin: 15px 20px 10px 20px;
            min-width: 75px;
        }

        &__button {
            &.button--secondary {
                padding: 15px 8px;
            }
        }

        &__infos {
            width: 245px;

            &__title {
                margin-bottom: 15px;
            }
        }
    }


    //======================================================================================================
    // Zoom
    //======================================================================================================
    .zoom {

        .swiper-container {
            height: 150px;
        }

        &__content {
            height: 110px;
        }

        &__image img {
            height: 150px;
            width: 220px;
            object-fit: cover;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .front-page {

        .home {

            &__section {
                height: 1115px;

                &:before {
                    height: 945px;
                }

                &__container {
                    display: block;
                }
            }
        }
    }

    // ALU
    .home-slideshow {
        position: relative;

        .swiper-container {
            height: 500px;

            .container {
                
                &--pagination {
                    transform: translateX(260px);
                }
            }
        }

        .video-container {
            .slideshow {
                video {
                    display: none;
                }
                img {
                    display: block;
                }
            }
        }
    }

    // Quick Access
    .access {

        height: 460px;

        &__list {
            flex-wrap: wrap;
            height: 325px;
        }

        &__item {
            width: 190px;
        }
    }

    // Post
    .post {

        width: 620px;

        &__content {

            img {
                width: 620px;
                object-fit: cover;
            }
        }

        &__content, &__link {
            width: 100%;
        }

        &__button {
            width: 300px;
        }
    }

    // Events
    .events {

        &__section {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            margin-top: 60px;
        }

        &__heading {
            width: 100%;
        }

        &__title {
            color: $color-white!important;
        }

        &__link {
            display: inline-block;
            width: 300px;
            height: 280px;
            margin: 0;
            padding: 20px;
        }

        &__date {
            width: 240px;
            height: 75px;
            flex-direction: row;
            margin: 10px 10px 0 10px;

            &:before {
                width: 240px;
                height: 75px;
            }

            svg {
                margin: 5px 20px;
            transform: rotate(-90deg);
            }
        }

        &__infos {
            width: 260px;
            padding-top: 20px;
        }

        &__buttons {
            margin-top: 25px;
            width: 100%;

            .button {
                width: 300px;
            }
        }
    }

    // Zoom
    .zoom {

        .swiper-container {
            height: 170px;
        }

        &__image {
            height: 170px;

            img {
                height: 170px;
                width: 100%;
                object-fit: cover;
            }
        }

        &__content {
            height: 130px;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .front-page {

        .home {

            &__section {
                margin-top: -260px;
                height: 1566px;

                &:before {
                    height: 1306px;
                    top: 260px;
                }
            }
        }
    }

    // ALU
    .home-slideshow {

        .swiper-container {
            height: 430px;

            .container {

                &--pagination {
                    bottom: 80px;
                    transform: translateX(100px);
                }
            }
        }
    }

    // Access
    .access {
        height: 740px;

        &__list {
            height:460px;
        }

        &__item {
            width: 140px;
            margin-top: -40px;
        }
    }

    // Post
    .post {
        width: 100%;

        &__heading {
            display: block;
        }

        &__title {
            margin-bottom: 10px;
        }

        &__navigation {
            position: initial;
            margin: unset;
        }

        .swiper-container {
            height: 414px;
            margin-bottom: 25px;
        }

        &__link {
            height: 414px;
        }

        &__content {
            position: relative;
            height: 184px;

            img {
                height: 184px;
                width: 100%;
                border-radius: $border-radius $border-radius 0 0;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                height: 184px;
                width: 100%;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 22.09%, #FFFFFF 100%);
            }
        }

        &__infos {
            position: initial;
            background: $color-white;
            border-radius: 0 0 10px 10px;
            height: 210px;
            padding: 0 20px 20px;
            justify-content: flex-start;
        }
    }

    // Events
    .events {

        flex-direction: column;
        justify-content: space-between;
        height: 808px;

        &__heading {
            margin-bottom: 10px;
        }

        &__link {
            margin-bottom: 20px;

            &:last-child {
                margin: 0!important;
            }
        }

        &__buttons {
            display: block;
            margin-top: 5px;

            .button:first-child {
                margin-bottom: 20px;
            }
        }
    }

    // Zoom
    .zoom {

        &__heading {
            margin-bottom: 25px;
        }

        &__title {
            margin-bottom: 10px;
        }

        &__navigation {
            position: initial;
            margin: 0;

            & div {
                background: $color-main;

                &:hover, &:focus {
                    background: $color-main;
                }
            }
        }
    }
}