#nav-main {
    z-index: 10;

    .container--nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 730px;
        transition: $duration ease-out;
    } 

    .nav-main-item {
        font-size: 1.375rem;
        line-height: 1.675rem;
        font-weight: $font-weight-bold;
        font-family: $font-family--heading;

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }
        &:hover {

            .nav-main-link::after {
                width: 100%;
                box-shadow: unset;
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: center;
            padding: 20px 0;
            color: $color-white;
            text-transform: uppercase;
            transition: all $duration ease-in-out;

            &:after {
                content: '';
                position: absolute;
                bottom: 15px;
                left: 0;
                width: 40px;
                height: 2px;
                border-radius: $border-radius;
                background: $color-third;
                box-shadow: 0px 0px 10px #000000;
                transition: all $duration ease-in-out;
            }

            
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            padding: 30px 15px;
            background: $color-main;
            box-shadow: $shadow;

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                height: 100px;
                margin: 0px; // Fix space beetween inline-flex
                margin-bottom: 25px;
                padding: 0;
                line-height: initial;
                border-radius: $border-radius;
                border: 1px solid $color-gray;
                overflow: hidden;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 0 15px 0 0;
                    margin-bottom: 1px; // Fix space beetween inline-flex
                    color: $color-white;
                    font-size: 1rem;
                    line-height: 1.25rem;
                    font-weight: $font-weight-bold;

                    .menu__content {
                        width: 170px;
                        height: 100px;
                        min-width: 170px;
                        margin-right: 15px;

                        &--notimg {
                            background: url('/wp-content/themes/noyau/assets/images/icon-page.png') no-repeat $color-bg--image;
                            background-position: center;
                        }

                        img {
                            width: 170px;
                            height: 100px;
                        }
                    }

                    &:hover {
                        color: $color-third;
                    }
                }
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    color: $color-white;

    .navicon {
        position: relative;
        display: block;
        height: 2px;
        top: -1px;
        width: 20px;
        margin-right: 15px;
        background: $color-third;
        transition: all $duration ease-out;
        box-shadow: 0px 0px 10px #000000;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-third;
            transition: all $duration ease-out;
            box-shadow: 0px 0px 10px #000000;
        }

        &:before {
            top: 7px;
        }

        &:after {
            top: -7px;
        } 
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {

        .menu__nav__list {
            width: 620px;
        }

        .nav-main-item {

            .sub-menu {
                .nav-drop {
                    li {
                        height: unset;
                        min-height: 60px;

                        a {
                            padding: 10px 15px;

                            .menu__content {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    #nav-main {

        .container--nav {
            margin-left: 180px;
        }

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            top: 90px;
            left: 0;
            right: 0;
            width: 100%;
            margin: auto;
            background: $color-main;
        }

        .nav-main-item {
            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 60px;
                padding: 0 20px;
                border-bottom: 1px solid $color-white;
                color: $color-white;
                text-transform: uppercase;

                &:first-child {
                    padding: 0 20px;
                }

                &:after {
                    display: none;
                }
            }

            .sub-menu {
                .nav-drop {
                    li {
                        margin-bottom: 15px;
                    }
                }
            }

            &:last-child {
                .nav-main-link {
                    border-bottom: 0;

                    &:after {
                        width: 0;
                        height: 0;
                        background: $color-bg--transparent;
                    }
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {
                background: $color-bg--neutral;
                box-shadow: 0 0 1px rgb(27 35 54 / 8%), 1px 1px 10px rgb(27 35 54 / 8%);

                .nav-main-link {
                    border-bottom: 1px solid $color-gray;
                    color: $color-main;
                }

                .sub-menu {
                    display: flex;
                    position: relative;
                    padding: 5px 10px;
                    box-shadow: none;

                    .nav-drop {
                        column-count: 1;
                    }

                    .nav-grandchild-item {
                        a {
                            padding: 10px;
                        }
                    }

                    li:last-child {
                        a {
                            border-bottom: 0;

                            &::before {
                                display: none;
                            }

                            &::after {
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                        }
                    }
                }
            }

        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
            width: 100%;
            height: 75px;
            margin: 0;
            padding: 0;
            font-size: 1.375rem;
            font-weight: $font-weight-bold;
            text-transform: uppercase; 
        }
    }
}

// 640
@media screen and (max-width: $small) {

    #nav-main {
        order: 1;
        width: 105px;

        .container--nav {
            margin: 0;
        }

        .menu__nav__list {
            top: 150px;
        }
    }
}