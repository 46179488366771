header {
    position: relative;
    z-index: 5;
}

.header-top {
    width: 100%;
    position: fixed;
    z-index: 2;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding-top: 30px;

    .container--identity {
        display: flex;
        justify-content: space-between;
        

        .site-identity {
            display: flex;
            align-items: center;
        }

        .identity {
            &__title {
                font-family: $font-family--heading;
                font-size: 0;
                font-weight: $font-weight--heading;
                line-height: 0;
                text-transform: uppercase;
                -webkit-text-size-adjust: none;
                color: $color-text;
                margin: 0;

                &:hover {
                    cursor: pointer;
                    color: $color-second;
                }
            }
        }

    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }
        
        &::before {
            background: $color-bg--neutral;
        }
    }
}

.tools {
    z-index: 2;
    height: 80px;

    .container--tools {
        display: flex;
        justify-content: flex-end;
        height: 80px;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        background: $color-bg--transparent;

        & > svg {
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            fill: $color-white; 
        }
    }

    .tool {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin: 0;
        padding: 0;
        border: 0;
        border-radius: $border-radius;
        box-shadow: 0px 0px 10px #000000;
        
        svg {
            fill: $color-text;
            transition: all $duration;
        }

        &:hover, &:focus {
            background-color: $color-main;
            cursor: pointer;

            svg {
                fill: $color-white;
            }
        }
    }

    &__accessibility {
        background-color: $color-third;

        svg {
            width: 40px;
            height: 40px;
        }
    }

    &__search {
        background-color: $color-white;
        margin-right: 25px!important;

        svg {
            width: 40px;
            height: 40px;
        }

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        color: $color-text;
    }
}

.header--fixed .header-top {

    height: 80px;
    padding-top: 0;
    background-color: $color-main;

    .site-identity {

        .identity {
            display: inline-flex;
            height: 40px;

            &__title {
                position: relative;
                display: inline-block;
                height: 40px;
                width: 40px;
                background-color: $color-second;
                border-radius: 10px;
                transition: $duration;

                &:hover {
                    background-color: $color-main;
                }

                &:before {
                    content: "";
                    position: absolute;
                    height: 40px;
                    width: 40px;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    background: url('/wp-content/themes/st-fulgent/assets/src/images/sprite-images/Pictos/ic-nav-home.svg');
                }

                svg {
                    display: none;
                }
            }
        }
    }

    .tools {
        .tool {
            box-shadow: unset;
        }
    }

    #nav-main {
        margin-right: 170px;

        .nav-main-item {
            .nav-main-link {
                &:after {
                    box-shadow: unset;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .header-top {

        .site-identity {

            .identity {

                &__title {

                    svg {
                        width: 150px;
                        height: 138px;
                    }
                }
            }
        }
    }

    .header--fixed .header-top {

        #nav-main {
            margin-right: unset;

            .menu__nav__list {

                width: 730px;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .header-top {
        position: absolute;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    .header-top {

        .container--identity {
            flex-wrap: wrap;
            max-height: 140px;
            flex-direction: column;
            height: 140px;

            .site-identity {
                width: 150px;
                margin-right: 45px;
            }

            .tools {
                height: 40px;
                width: 105px;
                margin-top: 15px;

                .container {
                    &--tools {
                        height: 40px;
                    }
                }
            }
        }
    }
}
