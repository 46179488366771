.breadcrumb {
    width: fit-content;
    background-color: white;
    margin-top: -30px;
    padding: 0 10px;
    z-index: 1;
    position: relative;
    border-radius: $border-radius;
    box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 60px;
    }

    li {
        position: relative;
        display: flex;
        align-items: center;
        color: $color-main;
        padding: 0 12px;

        // "Vous êtes ici" style
        //&:first-child {
        //    font-size: $font-size--text-small;
        //    font-weight: $font-weight-bold;
        //    text-transform: uppercase;
        //}

        &:hover {
            color: $color-second;
        }
        
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: -13px;
            margin: auto 0;
            width: 25px;
            height: 25px;
            color: $color-second;
            background: url('/wp-content/themes/st-fulgent/assets/src/images/sprite-images/Pictos/ic-nav-chevron-right.svg');
            background-size: contain;
            background-repeat: no-repeat;
            transition: all $duration;
        }

        &:last-child {
            &:after {
                content: none;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 40px;
            width: 100%;
            height: 1px;
        }

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}
