//=================================================================================================
// Buttons in general
//=================================================================================================
#{$btn} {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    margin: 5px 14px 5px 0;
    padding: $btn-padding;
    background: $btn-bg;
    border: $btn-border-width $btn-border-style $btn-border-color;
    border-radius: $btn-border-radius;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    color: $btn-color;
    text-transform: $btn-text-transform;
    text-decoration: none;
    text-align: center;
    transition: all $duration ease;

    &:hover,
    &:focus,
    &:active,
    &.is-active,
    &.is-checked {
        color: $btn-color--hover;
        background: $btn-bg--hover;
        border-color: $btn-border-color--hover;

        svg {
            fill: $btn-color--hover;
        }
    }

    svg {
        width: 26px;
        height: 26px;
        fill: $color-white;
        transition: all $duration ease;
    }
}


//=================================================================================================
// Buttons specifics
//=================================================================================================
.button {
    &--filter,
    &--toggler,
    &--toggler:active,
    &--toggler:focus,
    &--tag,
    &--tag--medium,
    &--tag--low {
        font-size: $btn-spe-font-size;
        font-weight: $btn-spe-font-weight;
        line-height: $btn-spe-line-height;
        padding: $btn-spe-padding;
        border-radius: $btn-spe-border-radius;
        height: 40px;
    }


    //===============================================
    // Button secondary (borders, neutral)
    //===============================================
    &--secondary {
        color: $btn-second-color;
        background: $btn-second-bg;
        border-color: $btn-second-border-color;
        padding: $btn-second-padding;

        svg {
            fill: $btn-second-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $btn-second-color--hover;
            background: $btn-second-bg--hover;
            border-color: $btn-second-border-color--hover;

            svg {
                fill: $btn-second-color--hover;
            }
        }
    }


    //===============================================
    // Button variant 1 (Background and color change)
    //===============================================
    &--variant {
        color: $btn-variant-color;
        background: $btn-variant-bg;
        border-color: $btn-variant-border-color;

        svg {
            fill: $btn-variant-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $btn-variant-color--hover;
            background: $btn-variant-bg--hover;
            border-color: $btn-variant-border-color--hover;

            svg {
                fill: $btn-variant-color--hover;
            }
        }
    }


    //===============================================
    // Button filter
    //===============================================
    &--filter {
        color: $btn-filter-color;
        background: $btn-filter-bg;
        border-color: $btn-filter-border-color;
        margin-right: 10px;

        svg {
            fill: $btn-filter-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $btn-filter-color--hover;
            background: $btn-filter-bg--hover;
            border-color: $btn-filter-border-color--hover;
        }
    }


    //===============================================
    // Button toggler
    //===============================================
    &--toggler,
    &--toggler:active,
    &--toggler:focus {
        color: $color-main;
        background: $color-bg--transparent;
        border-color: $btn-toggler-border-color;

        svg {
            fill: $color-main;
        }

        &.is-open {
            svg {
            transform: rotate(180deg);
            }
        }

        &:hover {
            color: $btn-toggler-color--hover;
            background: $btn-toggler-bg--hover;
            border-color: $btn-toggler-border-color--hover;

            svg {
                fill: $btn-toggler-color--hover;
            }
        }
    }


    //===============================================
    // Button tag
    //===============================================
    &--tag,
    &--tag--medium,
    &--tag--low {
        margin: 0 5px 10px 0;
    }

    &--tag--medium {
        opacity: 0.8;
    }

    &--tag--low {
        opacity: 0.6;
    }


    //===============================================
    // Button empty
    //===============================================
    &--empty {
        color: $btn-empty-color;
        background: $btn-empty-bg;
        border-color: $btn-empty-border-color;

        svg {
            fill: $btn-empty-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            background: $btn-empty-bg--hover;
            color: $btn-empty-color--hover;

            svg {
                fill: $btn-empty-color--hover;
            }
        }
    }
}


//===============================================
// Specifics links
//===============================================
.link-document {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 20px;
    cursor: pointer;
    font-family: $font-family;
    font-weight: $font-weight;
    font-size: $font-size--text;
    line-height: $line-height;
    text-decoration: underline;
    text-transform: none;
    color: $color--card;
    transition: all $duration ease;

    svg {
        width: 30px;
        height: 30px;
        background: $color-main;
        border-radius: 5px;
        fill: $color-white;
        margin-right: 10px;
        transition: all $duration ease-in-out;
    }

    &:hover {
        text-decoration: none;

        svg {
            background-color: $color-second;
        }
    }
}


//===============================================
// Animation Voir plus
//===============================================
.showX {
    opacity: 1;
    transition: all 0.30s ease-out;
}

.hideX {
    opacity: 0;
    transition: all 0.30s ease-in;
    pointer-events: none;
}

.sbl-circ-path {
    height: 48px;
    width: 48px;
    color: rgba(90, 90, 90, 0.2);
    position: relative;
    display: inline-block;
    border: 5px solid;
    border-radius: 50%;
    border-right-color: #5a5a5a;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}


//===============================================
// Reset button
//===============================================
.reset-button-style {
    display: inherit;
    margin: 0;
    padding: 0;
    background: inherit;
    border: 0;
    border-radius: 0;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    text-transform: inherit;
    text-decoration: inherit;
    text-align: inherit;
    transition: none;

    svg {
        width: inherit;
        height: inherit;
        fill: inherit;
        transition: none;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active,
    &.is-checked {
        background: inherit;
        border-color: inherit;
        color: inherit;

        svg {
            fill: inherit;
        }
    }
}

